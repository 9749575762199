import React, { Component } from "react";
import PrivacyHeader from "./components/PrivacyHeader";
import { Container, Table } from "react-bootstrap";
import axios from "axios";
import { getWebsiteConfig } from "./app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Loader from "./components/Loader";
import { Helmet } from "react-helmet";
export default class FAQ extends Component {
  state = {
    data: null,
    ans: 0,
  };
  componentDidMount() {
    const endPoint = `${process.env.GATSBY_STRAPI_BASE_URL}/api/website-configs?populate=template_items&populate=assets.value`;
    axios
      .get(endPoint)
      .then((res) => {
        setTimeout(() => {
          this.setState({
            data: getWebsiteConfig(res?.data?.data)?.home?.config?.qa,
          });
        }, 2000);
      })
      .catch((err) => {});
  }
  render() {
    if (!this.state.data) return <Loader />;
    return (
      <React.Fragment>
        <Helmet>
          <title>Frequently Asked Questions Answered | Junio</title>
          <meta name="description" content="Get answers to the most asked questions about Junio - How to use the app, what is Junio card and more." />
        </Helmet>
        <PrivacyHeader />
        <Container fluid id="faq" className="c8 c-pad">
          <div className="c-h1 c8-h1 text-center mt-5">{this.state.data.header}</div>
          <div className="row d-flex">
            <div className="col px-5 mx-5">
              {this.state.data.content.map((item, index) => {
                return (
                  <div className="acc-cont" key={index}>
                    <div
                      className="c8-p1 d-flex justify-content-between align-items-center"
                      onClick={() => {
                        this.setState({ ans: index });
                      }}
                    >
                      {item.question}
                      {this.state.ans === index ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                      )}
                    </div>
                    <div
                      className="c8-d1 text-left text-content"
                      style={{
                        display: this.state.ans === index ? "block" : "none",
                      }}
                      dangerouslySetInnerHTML={{__html: item.answer }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
